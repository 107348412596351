import { LinkedIn } from "@mui/icons-material";
import "./contact.scss"

export default function Contact() {
  return <div className='contact' id='contact'>
    <h1 className="sectionTitle">Neem contact op</h1>
    <p>Je kan mij bereiken via LinkedIn, met de knop hieronder!</p>
    <div className="contactButtons">
      <a href="https://www.linkedin.com/in/sacha-rupert-6b29b3109/"><LinkedIn className="icon"/></a>
    </div>
  </div>;
}