import ReactPlayer from "react-player";

export default function Project({ client, role, tasks, goals, description, imgSrcs, verticalVids, horizontalVids }) {


  return (
    <div className="project">
      <div className="projectDetails">
        <div className="projectText">
          <h2 className="client">{client}</h2>
          <p className="role"><span className="detail">Rol:</span> {role}</p>
          <p className="tasks"><span className="detail">Taken:</span> {tasks}</p>
          <p className="goals"><span className="detail">Doelen:</span> {goals}</p>
          <p className="description">{description}</p>
        </div>
        {horizontalVids.map((vidSrc, index) => {
          return (
            <div className="horizontalMedia" key={index}>
              <ReactPlayer
                url={vidSrc}
                controls
                width="auto"
                height="100%"
              />
            </div>
          )
        }
        )}
      </div>



      <div className="projectMedia">
        {imgSrcs.map((imgSrc, index) => {
          return (
            <img className="verticalMedia" key={index} src={"/assets/" + imgSrc} alt="Portfolio werk voor het Parool" />
          )
        })}

        {verticalVids.map((vidSrc, index) => {
          return (
            <div className="verticalMedia" key={index}>
              <ReactPlayer
                url={"/assets/videos/" + vidSrc}
                controls
                width="100%"
                height="auto"
              />
            </div>
          )
        }
        )}



      </div>
    </div>
  );
};