export const Projects = [
    {
      Client: "Het Parool.",
      Role: "Social Media Redacteur",
      Tasks: "Copywriting voor Instagram, Facebook, X en LinkedIn",
      Goals: "Nog geen doelen gemaakt, strategie wordt geschreven",
      Description: "They will try to close the door on you, just open it. Surround yourself with angels. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. The key to more success is to have a lot of pillows. The key is to enjoy life, because they don’t want you to enjoy life. I promise you, they don’t want you to jetski, they don’t want you to smile.",
      // Images: ["parool/Herdenking1.jpg","parool/Herdenking2.jpg","parool/Herdenking3.jpg","parool/Herdenking4.jpg","parool/Herdenking5.jpg","parool/insta1.jpeg","parool/insta2.jpeg","parool/insta3.jpeg","parool/natter_story.jpg","parool/Zoekplaatje2.jpg"],
      Images: ["parool/Herdenking1.jpg","parool/Herdenking2.jpg","parool/insta1.jpeg","parool/insta2.jpeg","parool/natter_story.jpg","parool/Zoekplaatje2.jpg"],
      VerticalVideos: [],
      HorizontalVideos: [],
    },
    {
      Client: "Cantona.",
      Role: "Creative",
      Tasks: "Concepten schrijven en content maken voor TikTok",
      Projects: "Willie.nl en Brasjas",
      Goals: "Eerste video (pas net 3 weken begonnen) met 25k views, doel is om een gemiddelde te behalen van 25k views",
      Description: "They will try to close the door on you, just open it. Surround yourself with angels. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. The key to more success is to have a lot of pillows. The key is to enjoy life, because they don’t want you to enjoy life. I promise you, they don’t want you to jetski, they don’t want you to smile.",
      Images: [],
      VerticalVideos: ["cantona1.MOV"],
      HorizontalVideos: [],
    },
    {
      Client: "DUNKD.",
      Role: "Creative Producer",
      Tasks: "Concepten schrijven en content maken voor TikTok",
      Projects: "Blokker en Intertoys",
      Goals: "Een van de meer succesvolle video’s, link naar FrankWatching",
      Description: "They will try to close the door on you, just open it. Surround yourself with angels. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. The key to more success is to have a lot of pillows. The key is to enjoy life, because they don’t want you to enjoy life. I promise you, they don’t want you to jetski, they don’t want you to smile.",
      Images: [],
      VerticalVideos: ["blokker1.MOV", "blokker2.MOV", "intertoys1.MOV"],
      // HorizontalVideos: ["https://www.youtube.com/watch?v=lyjVvP9Md_M", "https://www.youtube.com/watch?v=i5KeesvAw5k"],
      HorizontalVideos: ["https://www.youtube.com/watch?v=lyjVvP9Md_M"],
    },
    {
      Client: "StudiJob.",
      Role: "Social Media Coördinator",
      Tasks: "Nieuwe strategie schrijven en toepassen en content creatie",
      Goals: "TikTok kanaal opgezet met video's met gemiddeld 20k views met uitstekers. Implementeren van nieuwe social strategieën, zie before & after.",
      Description: "They will try to close the door on you, just open it. Surround yourself with angels. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. The key to more success is to have a lot of pillows. The key is to enjoy life, because they don’t want you to enjoy life. I promise you, they don’t want you to jetski, they don’t want you to smile.",
      Images: [],
      VerticalVideos: ["studijob1.MOV", "studijob2.MOV", "studijob3.MOV"],
      HorizontalVideos: [],
    },
    {
      Client: "Sense.info.",
      Role: "Social Media Adviseur en strateeg",
      Tasks: "Concepten schrijven en content maken voor TikTok en Instagram",
      Goals: "Videos liepen vast op 95 views, daar hebben we gemiddeld 200k vieuws van kunnen maken én 2 video’s van 1M views.",
      Description: "They will try to close the door on you, just open it. Surround yourself with angels. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. The key to more success is to have a lot of pillows. The key is to enjoy life, because they don’t want you to enjoy life. I promise you, they don’t want you to jetski, they don’t want you to smile.",
      Images: [],
      VerticalVideos: ["sense1.MOV","sense2.MOV","sense3.MOV"],
      HorizontalVideos: [],
    }
]
