import "./introduction.scss"

export default function Introduction() {

    return <div className="introduction" id="introduction">
            <div className="description">
                <h2>Hallo! Mijn naam is</h2>
                <h1>Sacha Rupert</h1>
                <h3>Social Media Creative</h3>
            </div>
        </div>;
}