import './app.scss';
import Navbar from './components/navbar/Navbar';
import Introduction from './components/introduction/Introduction';
import About from './components/about/About';
import Portfolio from './components/portfolio/Portfolio';
import Contact from './components/contact/Contact';
// import Services from './components/services/Services';

function App() {
  return (
    <div className="app">
      <Navbar />
      <div className ="sections">
        <Introduction />
        <About />
        {/* <Services /> */}
        <Portfolio />
        <Contact />
      </div>
    </div>
  );
}

export default App;
