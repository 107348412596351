import "./navbar.scss";
import { MenuItems } from "./MenuItems";
import { Menu } from "@mui/icons-material";
import React, {useState, useEffect} from 'react';

export default function Navbar() {
    const [toggleMenu, setToggleMenu] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const toggleNav = () => {
      if (screenWidth < 768) setToggleMenu(!toggleMenu)
    }
  
    useEffect(() => {
      const changeWidth = () => {
        setScreenWidth(window.innerWidth)
      }
      
      window.addEventListener('resize', changeWidth)
  
      // cleanup function from event listener
      return () => {
        window.removeEventListener('resize', changeWidth)
      }
    }, [])

    return (
        <div className='navbar'>
          <div className="logoAndIconContainer">
            <div className="logoContainer">
              <a href="#introduction" className="logo">Sacha Rupert</a>
            </div>
            <div className="hamburgerContainer">
                <Menu className="hamburgerIcon" onClick={toggleNav}/>
            </div>
            </div>
            <div className="navigation">
              {(toggleMenu || screenWidth > 768) && ( 
                <ul>
                {MenuItems.map((item, index) => {
                  return (
                    <li key={index} className="navigationItem">
                      <a href={item.url} onClick={toggleNav}>{item.title}</a>
                    </li>
                  )
                })}
              </ul>
              )}
            </div>
        </div>
      );

}