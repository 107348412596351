import "./portfolio.scss"
import Project from "./Project";
import { Projects } from "./Projects";
import React from 'react';


export default function Portfolio() {
  return (
    <div className='portfolio' id='portfolio'>
      <h1 className="sectionTitle">Projecten waar ik aan gewerkt heb</h1>
      <div className="portfolio-wrapper">
        {Projects.map((project, index) => {
          return (
            <Project
            key={index}
            client={project.Client}
            role={project.Role}
            tasks={project.Tasks}
            goals={project.Goals}
            description={project.Description}
            imgSrcs={project.Images}
            verticalVids={project.VerticalVideos}
            horizontalVids={project.HorizontalVideos}
          />
          )
        }
        )}
          
        {/* <div className="project parool">
          <Project
            client="Het Parool."
            role="Social Media Redacteur"
            tasks="Copywriting voor Instagram, Facebook, X en LinkedIn"
            goals="Nog geen doelen gemaakt, strategie wordt geschreven"
            description="They will try to close the door on you, just open it. Surround yourself with angels. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. The key to more success is to have a lot of pillows. The key is to enjoy life, because they don’t want you to enjoy life. I promise you, they don’t want you to jetski, they don’t want you to smile."
          />
          <div className="projectMedia paroolMedia">
            <img className="verticalMedia paroolImage" src="/assets/parool/Herdenking1.jpg" alt="Portfolio werk voor het Parool" />
            <img className="verticalMedia paroolImage" src="/assets/parool/Herdenking2.jpg" alt="Portfolio werk voor het Parool" />
            <img className="verticalMedia paroolImage" src="/assets/parool/Herdenking3.jpg" alt="Portfolio werk voor het Parool" />
            <img className="verticalMedia paroolImage" src="/assets/parool/Herdenking4.jpg" alt="Portfolio werk voor het Parool" />
            <img className="verticalMedia paroolImage" src="/assets/parool/Herdenking5.jpg" alt="Portfolio werk voor het Parool" />
            <img className="verticalMedia paroolImage" src="/assets/parool/insta1.jpeg" alt="Portfolio werk voor het Parool" />
            <img className="verticalMedia paroolImage" src="/assets/parool/insta2.jpeg" alt="Portfolio werk voor het Parool" />
            <img className="verticalMedia paroolImage" src="/assets/parool/insta3.jpeg" alt="Portfolio werk voor het Parool" />
            <img className="verticalMedia paroolImage" src="/assets/parool/natter_story.jpg" alt="Portfolio werk voor het Parool" />
          </div>
        </div>

        <div className="project cantona">
          <Project
            client="Cantona."
            role="Creative"
            tasks="Concepten schrijven en content maken voor TikTok"
            goals="Eerste video (pas net 3 weken begonnen) met 25k views, doel is om een gemiddelde te behalen van 25k views"
            description="They will try to close the door on you, just open it. Surround yourself with angels. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. The key to more success is to have a lot of pillows. The key is to enjoy life, because they don’t want you to enjoy life. I promise you, they don’t want you to jetski, they don’t want you to smile."
          />
          <div className="projectMedia cantonaMedia">

          </div>
        </div>

        <div className="project dunkd">
          <Project
            client="DUNKD."
            role="Creative Producer"
            tasks="Concepten schrijven en content maken voor TikTok"
            goals="Een van de meer succesvolle video’s, link naar FrankWatching"
            description="They will try to close the door on you, just open it. Surround yourself with angels. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. The key to more success is to have a lot of pillows. The key is to enjoy life, because they don’t want you to enjoy life. I promise you, they don’t want you to jetski, they don’t want you to smile."
          />
          <div className="projectMedia dunkdMedia">
            <div className="verticalMedia tiktokVideo"><TikTok url="https://www.tiktok.com/@blokker/video/7247511109966564635" /></div>
            <div className="verticalMedia tiktokVideo"><TikTok url="https://www.tiktok.com/@blokker/video/7246013951534697754" /></div>
            <div className="verticalMedia tiktokVideo"><TikTok url="https://www.tiktok.com/@intertoys.nl/video/7223474711492070682" /></div>
          </div>
          <div className="dunkdVideos">
            <div className="video"><YouTube videoId="lyjVvP9Md_M" opts={opts} /></div>
            <div className="video"><YouTube videoId="i5KeesvAw5k" opts={opts} /></div>
          </div>
        </div>

        <div className="project studijob">
          <Project
            client="StudiJob."
            role="Social Media Coördinator"
            tasks="Nieuwe strategie schrijven en toepassen en content creatie"
            goals="TikTok kanaal opgezet met video's met gemiddeld 20k views met uitstekers. Implementeren van nieuwe social strategieën, zie before & after."
            description="They will try to close the door on you, just open it. Surround yourself with angels. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. The key to more success is to have a lot of pillows. The key is to enjoy life, because they don’t want you to enjoy life. I promise you, they don’t want you to jetski, they don’t want you to smile."
          />
          <div className="projectMedia studijobMedia">
            <div className="verticalMedia tiktokVideo"><TikTok url="https://www.tiktok.com/@blokker/video/7247511109966564635" /></div>
          </div>
        </div>

        <div className="project sense">
          <Project
            client="Sense.info."
            role="Social Media Adviseur en strateeg"
            tasks="Concepten schrijven en content maken voor TikTok en Instagram"
            goals="Videos liepen vast op 95 views, daar hebben we gemiddeld 200k vieuws van kunnen maken én 2 video’s van 1M views."
            description="They will try to close the door on you, just open it. Surround yourself with angels. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. The key to more success is to have a lot of pillows. The key is to enjoy life, because they don’t want you to enjoy life. I promise you, they don’t want you to jetski, they don’t want you to smile."
          />
          <div className="projectMedia senseMedia">
            <div className="verticalMedia tiktokVideo"><TikTok url="https://www.tiktok.com/@sense.info/video/7110207407170571525" /></div>
            <div className="verticalMedia tiktokVideo"><TikTok url="https://www.tiktok.com/@sense.info/video/7154339654584536325" /></div>
          </div>
        </div> */}
      </div>
    </div>
  );
}