import "./about.scss"

export default function About() {


    return <div className="about" id="about">
        <h1 className="sectionTitle">Over mij</h1>

        <div className="pictureTextContainer">    
            <img className="aboutPicture" src="/assets/profielfoto.jpeg" alt="Profiel foto van Sacha Rupert"/>

            <div className="aboutText">
                <p>Sacha Rupert richt zich voornamelijk op organische groei op verschillende social media platforms, met een nadruk op kanalen zoals TikTok en Instagram. Haar overtuiging ligt in het unieke karakter van elk social media platform (Facebook, X, TikTok, Instagram, LinkedIn), waarbij elk platform een specifieke strategie en uniek soort content vereist.</p>
                <br/>
                <p>Haar specialiteit ligt in het ontwikkelen van nieuwe social media strategieën en het creëren van authentieke content die resoneren met de specifieke doelgroep. Dit resulteert in aanzienlijke groet en betrokkenheid bij diverse merken.</p>
                <br/>
                <p>Deze tekst hierboven heeft ze natuurlijk zelf geschreven en beschrijft globaal één van haar vele talenten. Dus, om iets meer persoonlijk te maken:</p>
                <br/>
                <p> <a href="https://paarden-etc.favorietje.nl" target="_blank" rel="noreferrer">Dit is de eerste keer dat ik verscheen op het internet.</a><br/>
                Ik ben geen 11 jaar meer en óók paardenfan, maar ik houd nog wel van moppen tappen.</p>
            </div>
        </div>
    </div>;
}

