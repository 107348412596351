export const MenuItems = [
    {
        title: "Over mij",
        url: "#about",
        className: "navigationItem"
    },
    // {
    //     title: "Diensten",
    //     url: "#services",
    //     className: "navigationItem"
    // },
    {
        title: "Portfolio",
        url: "#portfolio",
        className: "navigationItem"
    },

    {
        title: "Contact",
        url: "#contact",
        className: "navigationItem"
    }
]